<template>
  <div class="twelve-step content">
    <h1 class="header-title">Программа 12 Шагов Анонимных Наркоманов</h1>
    <p class="text-block">
      12 Шагов – это простая духовная, но не религиозная программа отказа от
      употребления наркотиков.
    </p>
    <h2 class="header-title_sub">Цель</h2>
    <p class="text-block">
      У каждой группы Сообщества «Анонимные Наркоманы» есть лишь одна главная
      цель – нести весть тому зависимому, который все еще страдает. Уже почти 70
      лет наша весть остается неизменной – любой, совершенно любой зависимый в
      состоянии остановиться в употреблении наркотиков, избавиться от тяги к
      употреблению и обрести новый путь в жизни.
    </p>
    <h2 class="header-title_sub">Участие</h2>
    <p class="text-block">
      <strong
        >Единственным условием для членства в Анонимных Наркоманах является
        желание прекратить употребление. Участие в АН бесплатно и анонимно
        <router-link class="router-link" to="/members"
          >(подробнее).</router-link
        ></strong
      >
    </p>
    <h2 class="header-title_sub">Программа</h2>
    <p class="text-block">
      Помощь одного зависимого другому обладает ни с чем не сравнимой
      терапевтической ценностью. Мы непрофессиональное сообщество и используем
      все, что помогает выздоравливать другим зависимым, которые с помощью
      Анонимных Наркоманов научились жить без наркотиков. Наше выздоровление
      стало возможным благодаря принципам, известным как Двенадцать Шагов
      Анонимных Наркоманов:
    </p>
    <ol class="text-block">
      <li>
        Мы признали, что мы бессильны перед нашей зависимостью, признали, что
        наши жизни стали неуправляемы.
      </li>
      <li>
        Мы пришли к убеждению, что Сила, более могущественная, чем мы сами,
        может вернуть нас к здравомыслию.
      </li>
      <li>
        Мы приняли решение препоручить нашу волю и наши жизни заботе Бога, как
        каждый из нас Его понимает.
      </li>
      <li>
        Мы провели глубокую и бесстрашную нравственную инвентаризацию самих
        себя.
      </li>
      <li>
        Мы признались Богу, самим себе и другому человеку в истинной сущности
        наших ошибок.
      </li>
      <li>
        Мы полностью подготовились к тому, чтобы Бог избавил нас от всех этих
        дефектов характера.
      </li>
      <li>Мы смиренно просили Его избавить нас от наших недостатков.</li>
      <li>
        Мы составили список всех людей, которым мы навредили, и обрели
        готовность возместить им всем ущерб.
      </li>
      <li>
        Мы напрямую возмещали причиненный этим людям ущерб, где это было
        возможно, кроме тех случаев, когда это могло повредить им или кому-либо
        еще.
      </li>
      <li>
        Мы продолжали проводить личную инвентаризацию и, когда совершали ошибки,
        без промедления признавали это.
      </li>
      <li>
        Мы стремились с помощью молитвы и медитации улучшить свой осознанный
        контакт с Богом, как каждый из нас Его понимает, молясь только о знании
        Его воли для нас и о силах для ее исполнения.
      </li>
      <li>
        Испытав духовное пробуждение в результате этих шагов, мы старались нести
        эту весть зависимым и применять эти принципы во всех наших делах.
      </li>
    </ol>
    <h2 class="header-title_sub">Религия</h2>
    <p class="text-block">
      «Анонимные Наркоманы» – это духовная, но не религиозная программа. Право
      на Бога в своем собственном понимании является общим для всех, в нем не
      кроется никаких хитростей. Это означает, что в Сообщество АН приходят
      зависимые, имеющие самые различные вероисповедания и представляющие
      широкий круг конфессий, а также атеисты (неверующие) и агностики.
    </p>
    <p class="text-block">
      Пусть вас не пугают слова «Бог» или «Высшая Сила» – некоторые члены
      сообщества понимают под «Высшей Силой» Сообщество АН или программу 12
      шагов.
    </p>
    <h2 class="header-title_sub">Кому подходит эта программа?</h2>
    <p class="text-block">
      Если вы зависимый или думаете, что у вас есть проблемы с наркотиками, мы
      предлагаем вам посещать собрания в течение, по крайней мере, 90 дней,
      чтобы познакомиться с членами АН и с нашей программой. Приходите пораньше,
      оставайтесь подольше и задавайте много вопросов – как до, так и после – и
      у вас будет возможность извлечь максимальную пользу из каждого собрания.
    </p>
    <p class="text-block">
      Добро пожаловать на наши
      <router-link class="router-link" to="/timetable">собрания</router-link>!
    </p>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "Программа 12 шагов";
  },
};
</script>